var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product column items-center" }, [
    _c(
      "div",
      {
        staticClass: "scrolling-wrapper-flexbox-row",
        staticStyle: {
          width: "100vw",
          "max-width": "600px",
          overflow: "hidden",
          "margin-top": "40px"
        }
      },
      [
        _c(
          "Flicking",
          { attrs: { options: { align: { camera: "20%", panel: "40px" } } } },
          _vm._l(6, function(i) {
            return _c(
              "div",
              { key: "panel-" + i, staticClass: "panel" },
              [
                _c(
                  "q-list",
                  {
                    staticClass:
                      "text-system-brand text-weight-medium q-list-depth"
                  },
                  [
                    _c(
                      "q-list-header",
                      {
                        staticClass:
                          "no-margin-top text-weight-bold text-tertiary module-title-size"
                      },
                      [
                        _c("q-icon", {
                          staticClass: "on-left-sm",
                          attrs: {
                            name: "ion-information-circle",
                            size: "32px"
                          }
                        }),
                        _vm._v(" Information\n          ")
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      { attrs: { link: "", tag: "label" } },
                      [
                        _c(
                          "q-item-side",
                          { staticClass: "text-center" },
                          [
                            _c("q-icon", {
                              attrs: {
                                name: "ion-repeat",
                                color: "blue-grey-10",
                                size: "33px"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "q-item-main",
                          [
                            _c("q-item-tile", { attrs: { label: "" } }, [
                              _vm._v("Fetch Clients")
                            ]),
                            _c("q-item-tile", { attrs: { sublabel: "" } }, [
                              _vm._v("Refresh list of users")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("q-item-separator"),
                    _c(
                      "q-item",
                      { attrs: { item: "", tag: "label" } },
                      [
                        _c(
                          "q-item-side",
                          { staticClass: "text-center" },
                          [
                            _c("q-icon", {
                              attrs: {
                                name: "ion-contact",
                                color: "blue-grey-10",
                                size: "33px"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "q-item-main",
                          [
                            _c(
                              "q-item-tile",
                              {
                                staticClass: "capitalize",
                                attrs: { label: "" }
                              },
                              [_vm._v("\n              Sam\n            ")]
                            ),
                            _c(
                              "q-item-tile",
                              { attrs: { sublabel: "" } },
                              [
                                _c("q-chip", { attrs: { dense: "" } }, [
                                  _vm._v("🇬🇧 English")
                                ]),
                                _c("q-chip", { attrs: { dense: "" } }, [
                                  _vm._v("🇿🇼 Shona")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "q-item-side",
                          { attrs: { right: "" } },
                          [
                            _c("q-icon", {
                              attrs: {
                                name: "ion-checkmark-circle",
                                color: "educate",
                                size: "33px"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }