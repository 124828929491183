<template>
  <div class="product column items-center">

    <div class="scrolling-wrapper-flexbox-row" style="width: 100vw; max-width: 600px; overflow: hidden; margin-top: 40px;">
      <Flicking :options="{ align: { camera: '20%', panel: '40px' } }">
        <div class="panel" v-for="i in 6" :key="`panel-${i}`">
          <q-list class="text-system-brand text-weight-medium q-list-depth">
            <q-list-header class="no-margin-top text-weight-bold text-tertiary module-title-size">
              <q-icon name="ion-information-circle" size="32px" class="on-left-sm"/> Information
            </q-list-header>
            <q-item link tag="label">
              <q-item-side class="text-center">
                <q-icon name="ion-repeat" color="blue-grey-10" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Fetch Clients</q-item-tile>
                <q-item-tile sublabel>Refresh list of users</q-item-tile>
              </q-item-main>
            </q-item>
            <q-item-separator/>
            <q-item item tag="label">
            <q-item-side class="text-center">
              <q-icon name="ion-contact" color="blue-grey-10" size="33px"/>
            </q-item-side>
            <q-item-main>
              <q-item-tile label class="capitalize">
                Sam
              </q-item-tile>
              <q-item-tile sublabel>
                <q-chip dense>🇬🇧 English</q-chip>
                <q-chip dense>🇿🇼 Shona</q-chip>
              </q-item-tile>
            </q-item-main>
            <q-item-side right>
              <q-icon name="ion-checkmark-circle" color="educate" size="33px"/>
            </q-item-side>
          </q-item>
          </q-list>
        </div>
      </Flicking>
    </div>
  </div>
</template>

<style lang="stylus">
@import url("../../node_modules/@egjs/vue-flicking/dist/flicking.css")

.panel
  min-width 300px
  min-height 200px
  margin-right 20px
  padding-top 40px
</style>

<script>
import Vue from 'vue'

import { Flicking } from '@egjs/vue-flicking'

export default {
  name: 'PageApps',
  components: {
    Flicking
  },
  data () {
    return {
      vueVersion: Vue.version
    }
  }
}
</script>
